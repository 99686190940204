<template>
<main class="">
  <header class="app-arrow-header text-center">
    <h4>Wybierz zdjęcie profilowe</h4>
  </header>
    <div v-if="isLoading">
        <Loader />
    </div>
  <section class="app-content-full bg-gray" v-if="!isLoading">
      <div class="wrapper">
          <div class="container">
              <div class="profile-circle m-0 w-100" >
                  <div class="profile-picture-wrapper" v-if="!editPhoto">
                      <div>
                          <a class="icon-big-circle" v-if="!editPhoto">
                              <img src="../assets/images/ph-face.png" alt="">
                          </a>
                      </div>
                  </div>
                  <Cropper v-if="editPhoto" ref="cropper"
                           stencil-component="circle-stencil" :src="editedImage"  :stencil-props="{movable: false, scalable: false, handlers: {}}"
                           @change="onChange($event)"
                  />
                  <div v-if="!upload" class="image-text text-center mt-5 px-4">
                      Dzięki Twojemu zdjęciu, inni czytelnicy będą mogli Cię poznać i nawiązać z Tobą książkową relację.
                  </div>
              </div>
          </div>
          <div class="container">
              <div v-if="upload" class="button-wrapper">
                  <button @click="uploadImage($event)" class="btn-black-full w-100 mt-4 mb-2">
                      Zapisz
                  </button>
              </div>
          </div>
          <div class="container" v-if="isUploaded">
              <div class="profile-name mb-0">
                  <div class="button-wrapper" v-show="!editPhoto">
                    <span class="button btn-black-full w-100 mt-4 mb-2" @click="$refs.file.click()">
                        <input type="file" ref="file" @change="loadImage($event)" accept="image/*;capture=camera">
                        Wybierz zdjęcie
                    </span>
                  </div>
              </div>
              <router-link to="/konto/uzupelnij-dane">
                  <button class="btn-black-full btn-black-border w-100 mt-0 mb-2" >Dodaj później</button>
              </router-link>
          </div>
      </div>

</section>
  <!-- <section class="app-content-full bg-gray">
    <div class="container h-100">
      <div class="row">
        <div class="col-12 button" @click="$refs.file.click()">
          <h2 class="text-center">Wgraj swoje zdjęcie</h2>
          <cropper v-if="editPhoto" ref="cropper"
          stencil-component="circle-stencil" :src="editedImage"/>
          <a href="#" class="icon-big-circle" v-if="!editPhoto">
            <i class="fas fa-file-upload"></i>
          </a>
          <input type="file" ref="file" class="hide-input" @change="loadImage($event)" accept="image/*;capture=camera">
        </div>
        <button class="btn-black-full w-100 mt-4 mb-2" @click.prevent="uploadImage">Zapisz</button>
      </div>
    </div>
  </section> -->
</main>
</template>
<script>
import { Cropper } from 'vue-advanced-cropper'
import Loader from '../components/Loader'

export default {
  name: 'OnboardingAvatar',
  components: {
    Cropper,
    Loader
  },
  data () {
    return {
      editPhoto: false,
      editedImage: null,
      upload: false,
      file: null,
      imageToSend: null,
      isUploaded: true,
      isLoading: false,
      coordinates: {},
      defaultPosition: null
    }
  },
  methods: {
    onChange ({ coordinates, canvas }) {
      this.coordinates = coordinates
      if (this.defaultPosition === null) {
        this.defaultPosition = { top: coordinates.top, left: coordinates.left, r: coordinates.width / 2 }
      }
    },
    loadImage (event) {
      this.editPhoto = true
      // Reference to the DOM input element
      var input = event.target
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader()
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.editedImage = e.target.result
        }
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0])
        this.upload = true
        this.file = input.files[0]
      }
    },
    uploadImage (event) {
      this.isLoading = true
      const formData = new FormData()
      formData.append('img', this.editedImage)
      formData.append('windowWidth', '0')
      formData.append('windowHeight', '0')
      formData.append('width', '0')
      formData.append('height', '0')
      formData.append('posx', (this.defaultPosition.left + this.defaultPosition.r - (this.coordinates.left + this.coordinates.width / 2)) + '')
      formData.append('posy', (this.defaultPosition.top + this.defaultPosition.r - (this.coordinates.top + this.coordinates.width / 2)) + '')
      formData.append('r', (this.coordinates.width / 2) + '')
      formData.append('scale', '1')

      this.$https.post('/update-img', formData, {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
        // data: `img=${this.editedImage}&windowWidth=295&windowHeight=500&width=295&height=433.797&posx=0&posy=0&r=91.45&scale=1`
      }).then(({ data }) => {
        this.$router.push({ path: '/konto/uzupelnij-dane' })

        this.image = data.response.img_url
        this.editPhoto = false
        this.upload = false
        this.isLoading = false
        this.false = true
        this.isUploaded = true
      })
        .catch(err => {
          console.log(err, '')
        })
    }
  }
}
</script>
<style lang="scss" scoped>
    .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .image-text{
            font-size: 14px;
        }
    }

    .submit-wrapper {
        margin-bottom: 30px;
    }

    .edit {
        &-input {
            width: 90%;
            height: 120%;
            border: none;

            &:disabled {
                background-color: #fff;
            }
        }
    }

    .upload-example-cropper {
        border: solid 1px #EEE;
        height: 300px;
        width: 100%;
    }

    .button-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 17px;
    }

    .button {
        color: white;
        font-size: 14px;
        padding: 10px 20px;
        background: #000;
        cursor: pointer;
        transition: background 0.5s;
        font-weight: 100
    }

    .button:hover {
        background: #000;
    }

    .button input {
        display: none;
    }

    .loader-wrapper{
        height: calc(100vh - 64px);
    }
</style>
